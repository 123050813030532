<template lang="pug">
.margin-popover.d-flex.flex-column(:style="popoverStyle")
  .margin-popover-close
    UilTimes.cursor-pointer(size="20px" @click.native="onClose")
  .margin-popover-lottie
    lottie(filename="spacing_animation")
  Badge(:text="$t('new')")
  om-heading(h6) {{ $t('marginPopover.title') }}
  om-body-text(bt400sm) {{ $t('marginPopover.description') }}
</template>

<script>
  import Cookie from 'js-cookie';
  import { UilTimes } from '@iconscout/vue-unicons';

  export default {
    components: {
      UilTimes,
      Badge: () => import('@/components/Badge.vue'),
    },
    props: {
      position: {
        type: Object,
        required: true,
      },
    },
    computed: {
      popoverStyle() {
        const { top, left, width } = this.position;
        return {
          top: `${top}px`,
          left: `${left - 48 - width}px`,
        };
      },
    },
    mounted() {
      const dontShow = Cookie.get('x-om-campaign-popover-dont-show');
      const openedInNearPast = Cookie.get('x-om-campaign-popover-seen-in-near-past');

      if (dontShow || openedInNearPast) {
        this.$emit('close');
        return;
      }

      const count = Number(Cookie.get('x-om-campaign-popover-shown-count') || 0);

      Cookie.set('x-om-campaign-popover-shown-count', count + 1, { expires: 360 });

      const in2Hours = Date.now() + 2 * 60 * 60 * 1000;
      Cookie.set('x-om-campaign-popover-seen-in-near-past', 1, { expires: new Date(in2Hours) });
    },
    methods: {
      onClose() {
        const count = Number(Cookie.get('x-om-campaign-popover-shown-count') || 0);
        if (count >= 2) {
          Cookie.set('x-om-campaign-popover-dont-show', '1', { expires: 365 });
        }
        this.$emit('close');
      },
    },
  };
</script>

<style lang="sass">
  .margin-popover
    position: absolute
    background: #FFFFFF
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.04)
    border-radius: 4px
    width: 16.75rem
    gap: .5rem
    padding: 1rem
    z-index: 999999999999999999
    &-close
      position: absolute
      top: 1rem
      right: 1rem
      z-index: 999999999999999999
    &-lottie
      width: 100%
    .badge
      font-size: .875rem
      line-height: 1.25rem
      width: 3.25rem
      border-radius: 40px
</style>
